@import './variables.scss';
@import './mixins.scss';

.dropdown-menu {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    @include transition(all);
    @include transition-duration(250ms);
    @include backface-visibility(hidden);
    margin-top: 1px;

    & > li > a {
        padding: 8px 17px;
        @include transition(background-color);
        @include transition-duration(300ms);
        font-family: roboto;
    }

    &.dropdown-menu-lg {
        width: 400px;
    }

    &.dropdown-menu-sm {
        width: 150px;
    }

    &.dropdown-menu-right {
        right: 0;
        left: auto;

        & > li > a {
            text-align: right;
        }
    }

    &.dm-icon {
        & > li > a > .zmdi {
            line-height: 100%;
            vertical-align: top;
            font-size: 18px;
            width: 20px;
            text-align: center;
            margin-right: 10px;
        }
    }

    &:not([class*="bgm-"]) {
        & > li > a {
            color: #4C4C4C;

            &:hover {
                color: #000;
            }
        }
    }

    &[class*="bgm-"] {
        & > li > a {
            font-weight: 300;
            color: #fff;
        }
    }
}

.dropdown:not([data-animation]), .btn-group, .date-picker {
    .dropdown-menu {
        @include scale(0);
        @include opacity(0);
        display: block;
    }
}

.dropdown:not([data-animation]), .bootstrap-select, .btn-group, .date-picker {
    .dropdown-menu {
        &.pull-right, &.dropdown-menu-right {
            @include transform-origin(top right);
        }

        &:not(.pull-right):not(.dropdown-menu-right) {
            @include transform-origin(top left);
        }
    }
}


.dropup:not([data-animation]) {
    .dropdown-menu {
        &.pull-right, &.dropdown-menu-right {
            @include transform-origin(bottom right);
        }

        &:not(.pull-right):not(.dropdown-menu-right) {
            @include transform-origin(bottom left);
        }
    }
}

.dropdown:not([data-animation]), .dropup, .bootstrap-select, .btn-group {
    &.open {
        .dropdown-menu {
            @include scale(1);
            @include opacity(1);
        }
    }
}

.dropdown-header {
    padding: 3px 17px;
    margin-top: 10px;
    color: #b1b1b1;
    text-transform: uppercase;
    font-weight: normal;
}

.btn-group.open .dropdown-toggle {
    box-shadow: none;
}

.custom-fields-popup-wrapper {
    .dropdown-menu {
        display: block;
        width: 100%;

        li.c-indigo > a {
            color: #3F51B5 !important;
        }
    }
}

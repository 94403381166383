html,
body {
  font-size: 16px;
  min-height: 100vh;
  height: 100%;
}

body {
  background: #FAF7F7;
  margin: 0;
  font-family: Source Sans Variable;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  text-rendering: optimizeLegibility;
  /* letter-spacing: 0.05em; */
  font-size: 14px;
  color: #414141;
}

#root {
  overflow: hidden;
  height: 100%;
}

@font-face {
  font-family: "Source Sans Variable";
  src: local("sourceSans"),
   url("./fonts/SourceSans3VF-Roman.otf") format("truetype");
  font-weight: 100 900;

  }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.font-face-gm {
  font-family: "Source Sans Variable";
 }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.call-animation {
  animation: play 2s ease infinite;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}
@keyframes play {
  0% {
    transform: scale(1);
  }
  15% {
    box-shadow: 0 0 0 20px rgba(243, 3, 3, 0.4);
  }
  25% {
    box-shadow: 0 0 0 24px rgba(238, 5, 5, 0.4),;
  }
  40% {
    box-shadow: 0 0 0 28px rgba(185, 29, 29, 0.4), 0 0 0 46px rgba(148, 59, 59, 0.2);
  }
  40% {
    box-shadow: 0 0 0 32px rgba(243, 9, 9, 0.4), 0 0 0 56px rgba(187, 61, 61, 0.2);
  }
  /* 60% {
    box-shadow: 0 0 0 64px rgba(243, 9, 9, 0.4), 0 0 0 102px rgba(187, 61, 61, 0.2);
  } */
}

.App-link {
  color: #61dafb;
}

.file-txt {
  background: url('/assets/icn_txt@2x.png') no-repeat center center;
}
.file-docx, .file-doc {
  background: url('/assets/icn_docs@2x.png') no-repeat center center;
}
.file-xlsx, .file-xls {
  background: url('/assets/icn_xls@2x.png') no-repeat center center;
}
.file-pdf {
  background: url('/assets/icn_pdf@2x.png') no-repeat center center;
}
.file-pptx, .file-ppt {
  background: url('/assets/ic_powerpoint@2x.png') no-repeat center center;
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
.scrollbar::-webkit-scrollbar {
  background-color:#fff;
  width:3px
}

/* background of the scrollbar except button or resizer */
.scrollbar::-webkit-scrollbar-track {
  background-color:#fff
}
.scrollbar::-webkit-scrollbar-track:hover {
  background-color:#f4f4f4
}

/* scrollbar itself */
.scrollbar::-webkit-scrollbar-thumb {
  background-color:#babac0;
  border-radius:16px;
  border:5px solid #fff
}
.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color:#a0a0a5;
  border:4px solid #f4f4f4
}

/* set button(top and bottom of the scrollbar) */
.scrollbar::-webkit-scrollbar-button {display:none}

/* div box */
.scrollbar {
  min-width: 150px;
  background: #fff;
  overflow-y: scroll}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

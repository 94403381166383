@import './variables.scss';
@import './mixins.scss';
@import './geoSearch.css';

.toggleLabel {

}
.centered-axis-xy {
  position: absolute;
  left: 15%;
  top: 15%;
  font-size: 20px;
}

//Margin classes
.m-r-30 {
  margin-right: 30px;
}
.m-r-20 {
  margin-right: 20px;
}
.m-r-10 {
  margin-right: 10px;
}
.m-l-5 {
  margin-left: 5px;
}
.m-l-10 {
  margin-left: 10px;
}
.m-l-15 {
  margin-right: 15px;
}
.m-t-5 {
  margin-top: 5px;
}
.m-t-10 {
  margin-top: 10px;
}
.m-t-15 {
  margin-top: 15px;
}
.m-t-20 {
  margin-top: 15px;
}
.m-t-25 {
  margin-top: 5px;
}
.m-b-30 {
  margin-bottom: 5px;
}
.m-b-20 {
  margin-bottom: 10px;
}
.m-b-15 {
  margin-bottom: 15px;
}
.m-b-5 {
  margin-bottom: 5px;
}

//Padding classes
.p-5 {
  padding: 5px !important;
}
.p-r-15 {
  padding-right: 15px;
}
.p-r-10 {
  padding-right: 10px;
}
.p-r-30 {
  padding-right: 30px;
}
.p-r-0 {
  padding-right: 0px;
}
.p-l-0 {
  padding-left: 0px;
}
.p-l-5 {
  padding-left: 5px;
}

.p-l-10 {
  padding-left: 10px !important;
}

.p-l-20 {
  padding-right: 20px;
}
.p-t-5 {
  padding-top: 5px;
}
.p-t-10 {
  padding-top: 10px;
}
.p-t-40 {
  padding-top: 40px;
}
.p-b-5 {
  padding-bottom: 5px;
}
.p-b-10 {
  padding-bottom: 10px;
}
.p-b-15 {
  padding-bottom: 15px;
}
.c-gray {
    color: #9e9e9e!important;
}
.p-10 {
  padding: 10px;
}


.m-w-200 {
  min-width: 200px !important;
}

.scroll-sm::-webkit-scrollbar {
    width: 3px;
}

.scroll-sm::-webkit-scrollbar-track {
    background: #FFFFFF;
}

.scroll-sm::-webkit-scrollbar-thumb {
    background: #1C5BFF;
    border-radius: 5px;
}

.scroll-drawer::-webkit-scrollbar {
  width: 2px;
}

.scroll-drawer::-webkit-scrollbar-track {
  background: #FFFFFF;
}

.scroll-drawer::-webkit-scrollbar-thumb {
  background: #1C5BFF;
  border-radius: 5px;
}

.word-wrap-text {
  word-break: break-word;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 9;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 13px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid transparent;
    /* border-radius: 2px; */
    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,0.175);
    box-shadow: 0 6px 12px rgba(0,0,0,0.175);
}

.text-align-center{
  text-align: center;
}

#chat_container {
  a {
    text-decoration: none;
  }

  z-index: 1080;

  //ALERT:: Commenting below CSS because this is going to impact ul and ol of broadcast rich text. Handling the same wherever required
  //NOTE:: Do not uncomment this CSS. Keeping this here with comment to remind that its very very importnat to keep the rich text editor working
  // If you want this style on some element use it on specific element not the whole chat client. In case of any doubt contact Rizvan/Prashant.

  //ul, ol {
  //  list-style: none;
  //  padding: 0 !important;
  //}
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  font-size: 13px;
  .modal-content {
    z-index: 1080;
  }
  .modal-dialog {
    width: 70%;
  }
  .no-contact-container, .no-group-container, .no-broad-group-container{
      height: 100%;

      h2 {
        font-size: 17px;
        font-weight: 400;
        line-height: 100%;
        margin: 0px;
        text-align: center;
      }

      li {
          a {
              color: #7a7a7a;
              display: block;
              padding: 11px 10px 11px 45px;
              position: relative;
              text-decoration: none;

              .zmdi {
                font-size: 20px;
                left: 16px;
                padding: 10px 0;
                position: absolute;
                top: 0;
              }
          }
      }
  }
  #massenger_notification {
    .notification {
      padding: 5px 15px;
      background-color: $m-green;
      color: white;
      position: absolute;
      border-radius: 50px;
      right: 35%;
      z-index: 1;
    }
  }
  .contact-available-container {
    height: 100%;
    ul, ol {
      list-style: none;
      padding: 0 !important;
    }
  }
  .chat-available-container {
    height: 100%;
  }

  .scheduled-msg.broadcast-bubble {
    width: 100% !important;
    max-width: 100% !important;
  }

  .location-message {
    .address{
      padding-top: 5px;
      width: 200px;
    }
    .message-hint {
      font-size: 10px;
    }
    img {
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.158927);
    }
  }

  .sender {
    font-size: 75%;
    opacity: 0.7;
  }

  .parent {
    padding-left: 10px;
    cursor: pointer;
  }

  .discard-normal-reply, .discard-rich-reply{
    i {
      float: right;
      padding-right: 10px;
    }
  }

  .reply-to-text {
    .quote-message {
      display: block;
    }

    .seekObjContainer {
      input {
        bottom: 22px !important;
        width: 70%;
      }

      progress {
        position: absolute;
        bottom: 26px !important;
        width: 70%;
      }
    }
  }

  .normal-reply-editor {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-top: 1px solid !important;
  }

  #normal_editor_reply_template {
    border-top-left-radius: 8px;
    background-color: #f8f8f8;
    width: 90%;
    border-top-right-radius: 8px;
    padding-left: 10px;

    .parent-msg-content {
      max-width: 99%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 445px;
      display: inline-block;
      overflow-wrap: break-word;
      max-height: 18px;
    }
  }

  #rich_editor_reply_template {
    border-top-left-radius: 8px;
    background-color: #f8f8f8;
    width: 100%;
    border-top-right-radius: 8px;
    padding-left: 10px;
    overflow-wrap: break-word;

    .parent-msg-content {
      p, h1, h2, h3, h4, h5, h6 {
        max-width: 99%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 525px;
        display: inline-block;
      }
    }
  }
  .no-bg-color {
    background-color: #ffffff !important;
  }

  .quote-icon {
    float: left;
    width: 18px !important;
    height: 18px !important;
  }

  .quote-message {
    border-bottom: 1px solid;
    display: none;
  }

  .rich-reply-content{
    p {
      margin-bottom: 0px !important;
    }
  }

  .broadcast-bubble {
    width: 93% !important;
    max-width: 93% !important;
    padding-bottom: 0px !important;
    p {
      margin-bottom: 0px;
    }
    .view-full-image {
      text-align: center;
    }
    .audioRange {
      width: 390px !important;
      bottom: 42%;
    }
    progress {
      width: 390px !important;
    }

  }


  .broadcast-reply-view-bubble {
    width: 100% !important;
    max-width: 100% !important;
    padding-bottom: 0px !important;
    p {
      margin-bottom: 0px;
    }
    .view-full-image {
      text-align: center;
    }
    .audioRange {
      width: 390px !important;
      bottom: 22px;
    }
    progress {
      width: 390px !important;
    }

  }

  .broadcast-recording-actions{
    width: 90% !important;
  }
  .eva-chat-container {
    height: 550px !important;
    background-color: #fff;
    .eva-chat-disabled, .empty-scheduled-msg {
      &.empty{
        min-height: 550px;
        .empty-content {
          width: 80%;
          display: inline-block;
        }
        .eva-icon {
          font-size: 40px;
          color: #4b77be;
          font-weight: 300;
          overflow: hidden;
          position: relative;
          display: block;
        }
      }
      small {
        font-size: 14px;
        line-height: 1.3;
      }
    }

  }
  .eva-chat-container .left {
    float: left;
    width: 37.6%;
    height: 100%;
    border: 1px solid #eee;
    background-color: #f8f8f8;
    position: relative;

    .tab-nav {
      overflow: hidden;
      .width-20pc {
        width: 20%
      }

      .width-24pc {
        width: 24%
      }

      .width-28pc {
        width: 28%;
      }

      li {
        text-align: center;
        //width: 23%;

        a {
          padding: 5px;
        }

        &.active {
          a {
            &::after {
              background: $m-indigo;
            }
          }
        }
      }
    }

    .top {
      position: relative;
      width: 100%;
      height: 74px;
      padding: 16px 20px;

      &:after {
        position: absolute;
        bottom: 0;
        left: 50%;
        display: block;
        width: 100%;
        height: 1px;
        content: '';
        background-color: #eee;
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
      }
    }

    input {
      float: left;
      width: 100%;
      height: 42px;
      padding: 0 15px;
      border: 1px solid #e6e6e6;
      background-color: #fff;
      border-radius: 4px;
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 400;

      &:focus {
        outline: none;
      }
    }

    a.search {
      display: block;
      float: left;
      width: 42px;
      height: 42px;
      margin-left: 10px;
      border: 1px solid #e6e6e6;
      background-color: #00b0ff;
      border-radius: 50%;
    }

    .tab-content {
      width: 100%;
      height: calc(100% - 115px);
      overflow: auto;
      margin-bottom: 0;
      padding: 0px;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 0;
      }

      &::-webkit-scrollbar-thumb {
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        -webkit-border-radius: 7px;
        background-color: rgba(0, 0, 0, 0.15);
      }

      .person {
        width: 100%;
        padding: 10px 5% 10px;
        cursor: pointer;
        background-color: #f8f8f8;
        border-bottom: 1px solid #eee;

        img {
          width: 40px;
          height: 40px;
          margin-right: 12px;
          border-radius: 50%;
          border: 2px solid #fff;
        }

        .unread-count {
          font-size: 15px;
          padding: 0px 4px;
          color: $m-white;
          background-color: $m-red;
          border-radius: 12px;
          display: inline-flex;
          height: 20px;
          min-width: 20px;
          max-width: 18%;
          overflow: hidden;
          justify-content: center;
        }

        .details {
          width: 50%;
          white-space: nowrap;
          text-overflow: ellipsis;

          .name {
            color: #1a1a1a;
            font-size: 14px;
            line-height: 22px;
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: 500;
            width: 80%;
            overflow: hidden !important;
            text-overflow: ellipsis;
          }
          .preview {
            font-size: 13px;
            color: #999;
            overflow: hidden !important;
            text-overflow: ellipsis;
          }
        }

        &.active, &:hover {
          background-color: #1e88e5;

          .details {
            .name, .preview {
              color: #fff;
            }
          }
        }
      }
    }
  }
  .eva-chat-container .right {
    position: relative;
    float: left;
    width: 62.4%;
    height: 100%;

    .top {
      width: 100%;
      height: 74px;
      padding: 20px;
      background-color: #f8f8f8;
      //border-bottom: 1px solid #e6e6e6;

      .top-user-avatar {
        margin-right: 15px;
        img {
          height: 40px;
          width: 40px;
          border-radius: 50%;
          border: 2px solid #fff;
        }
      }

      .user-details {
        margin-top: -5px;
        width: 65%;

        .name {
          font-size: 20px;
          color: #333;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        .email {
          color: #999;
          font-size: 14px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      .schedule-message-back-btn {
        font-size: 20px;
        color: #333;
        padding-top: 5px;
      }

      .schedule-heading {
        padding-top: 5px;
        padding-left: 15px;
        width: 65%;
        font-size: 20px;
        color: #333;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

    }

    textarea#user_chat_msg {
        background-color: #f8f8f8;
        border: 1px solid #e6e6e6;
        border-radius: 8px;
        bottom: 0;
        color: #565867;
        resize: none;
        transition: background-color 0.2s ease 0s, box-shadow 0.2s ease 0s;

        box-sizing: border-box;
        font-family: "intercom-font","Helvetica Neue",Helvetica,Arial,sans-serif;
        font-size: 15px;
        font-weight: 400;
        height: 100%;
        overflow-wrap: break-word;
        padding: 8px 10px;
        white-space: pre-wrap;
        width: 90%;

        cursor: text;

        &:focus {
            background-color: #fff;
            outline: medium none;
        }
    }
  }

  .eva-chat-container .right .chat {
    position: relative;
    display: none;
    min-height: 100%;
    padding: 0 20px 0px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .eva-chat-container .right .chat.active-chat {
    display: block;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: block;
    padding-top: 5px;
  }
  .eva-chat-container .right .chat.active-chat .bubble {
    max-width: 70%;
    word-wrap: break-word;
    .forwarded {
        display: none;
    }
    .forwarded.true {
      display: block;
      .icon-forward {
        @include z-depth-bottom(1);
        background-image: url('/assets/ic_forward.svg');
        background-repeat: no-repeat;
        box-shadow: none;
        float: left;
        width: 15px;
        height: 15px;
      }
      font-size: 11px;
      font-style: italic;
      padding-right: 20px;
      padding-bottom: 2px;

    }
  }

  .eva-chat-container .right .chat.active-chat .image-msg .bubble {
    .view-full-image {
      //max-width: 225px;
      text-align: center;
    }
  }

  .chat-container .right .write-broadcast {
    height: 53px !important;
    background-color: #f8f8f8;
    width: 100%;
    padding: 5px 5px !important;
  }

  .chat-container .right .allow-reply-section {
      margin-left: -20px;
    .allow-reply-label {
      vertical-align: top;
    }
    .allow-reply-description {
        float: left;
        margin-top: -7px;
        font-size: 10px;
    }
    .toggle-switch {
      position: relative;
      display: inline-block;
      width: 38px;
      height: 12px;
    }

    .toggle-switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      left: -4px;
      bottom: -2px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }

    input:checked + .slider {
      background-color: #2196F3;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #2196F3;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }

    .slider.round {
      border-radius: 24px;
    }

    .slider.round:before {
      border-radius: 50%;
    }

  }

  .eva-chat-container .right .write {
    height: 62px;
    background-color: #f8f8f8;
    width: 100%;
    padding: 10px 20px;
  }
  .eva-chat-container .right .write input {
    font-size: 16px;
    float: left;
    width: calc(100% - 84px);
    height: 40px;
    padding: 0 10px;
    color: #1a1a1a;
    border: 0;
    outline: none;
    background-color: white;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    border-radius: 4px;
    border: 1px solid #e6e6e6;
  }
  .eva-chat-container .right .write .send-btn.send, .cancel-btn.cancel, .preview-btn, .mike-btn.mike, .attachment .action-btn.action, .cancel-schedule-btn.cancel {
    height: 40px;
    line-height: 40px;
    background: white !important;
    display: inline-block;
    width: 40px;
    float: right;
    color: #cacaca;
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    font-size: 16px;
    text-align: center;
    padding: 0;
    box-shadow: none;
    &.active {
      color: white;
      background: #3F51B5 !important;
    }
    .material-icon{
      vertical-align: middle;
      font-size: 20px;
    }
  }

  .eva-chat-container .right .time {
    color: #999;
  }

  .eva-chat-container .right .bubble {
    font-size: 13px;
    position: relative;
    display: inline-block;
    clear: both;
    margin-bottom: 5px;
    padding: 7px 10px;
    vertical-align: top;
    border-radius: 3px;
    -webkit-font-smoothing: auto;
    font-weight: 100;
    .time-inidcator {
      font-size: 11px !important;
    }
    .placeholder-img {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 200px;
      width: 200px;
      background: rgba(0,0,0,0.1);
    }
    .download {
      height: 48px;
      cursor: pointer;
    }
    .action-btn.retry {
      cursor: pointer;
      &:before {
        font-family: 'Material-Design-Iconic-Font';
        content: '\f22a';
        padding-right: 5px;
        font-size: 48px;
        color: #939393;
      }
    }

    .action-btn.sending {
      border: 2px solid #ffffff;
      border-radius: 50%;
      border-top: 2px solid #4954B8;
      width: 50px;
      height: 50px;
      -webkit-animation: spin 2s linear infinite; /* Safari */
      animation: spin 2s linear infinite;
      margin-top: 7px;
      padding-right: 5px;
    }

    .preview {
      cursor: pointer;
    }
    img {
      max-height: 200px;
      max-width: 200px;
    }

    .file-message-container {
      display: flex;
      align-items: center;
      width: 250px;
      border-radius: 5px;

      padding: 5px;
      .file-name {
        width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 10px;
      }

      .file-icon {
        border-radius: 0px;
      }
    }

  }
  .eva-chat-container .right .bubble:before {

  }
  .eva-chat-container .right .bubble.you {
    float: left;
    background-color: #f8f8f8;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    .file-message-container {
      background: #FFF;
    }
  }
  .eva-chat-container .right .missed-call:before {
    font-family: 'Material-Design-Iconic-Font';
    content: '\f2b8';
    padding-right: 5px;
  }
  .eva-chat-container .right .call-status {
    font-weight: 400;
  }
  .eva-chat-container .right .bubble.you:before {
  }
  .eva-chat-container .right .bubble.me {
    float: right;
    background-color: #E3F2FF;
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
    .file-message-container {
      background: #C0D7EB;
    }
  }
  .eva-chat-container .right .bubble.me.receive:before {
    font-family: 'Material-Design-Iconic-Font';
    content: '\f2ed';
    padding-right: 5px;
  }
  .eva-chat-container .right .bubble.you.receive:before {
    font-family: 'Material-Design-Iconic-Font';
    content: '\f2e9';
    padding-right: 5px;
  }
  .eva-chat-container .right .call-duration.no {
    display: none;
  }

  .eva-chat-container .right .call-duration {
    font-size: 11px;
  }

  .eva-chat-container .right .bubble.missed-call {
    color: #FF0019;
  }

  .message-status {
    @mixin msg-status($content: '\f3ec') {
      &:before {
        content: $content;
        font-family: $font-icon-md;
        font-size: 16px;
      }
    }

    &.delivered {
      @include msg-status('\f267');
    }

    &.sent {
      @include msg-status('\f26b');
    }

    &.sending {
      @include msg-status('\f3ec');
    }

    &.failed {
      @include msg-status('\f1f4');
      color: $brand-danger;
    }

    &.read {
      @include msg-status('\f267');
      color: $brand-primary;
    }

    &.open {
      @include msg-status('\f267');
      color: #468F49;
    }
  }
  .eva-chat-container .right .bubble.me:before {
    // right: -3px;
    // background-color: #f8f8f8;
  }
  .eva-chat-container .right .conversation-start {
    position: relative;
    width: 100%;
    margin: 10px 0px;
    text-align: center;
  }
  .eva-chat-container .right .conversation-start span {
    font-size: 14px;
    display: inline-block;
    color: #999;
  }
  .eva-chat-container .right .conversation-start span:before, .eva-chat-container .right .conversation-start span:after {
    position: absolute;
    top: 10px;
    display: inline-block;
    width: 30%;
    height: 1px;
    content: '';
    background-color: #e6e6e6;
  }
  .eva-chat-container .right .conversation-start span:before {
    left: 0;
  }
  .eva-chat-container .right .conversation-start span:after {
    right: 0;
  }

  @keyframes slideFromLeft {
    0% {
      margin-left: -200px;
      opacity: 0;
    }
    100% {
      margin-left: 0;
      opacity: 1;
    }
  }
  @-webkit-keyframes slideFromLeft {
    0% {
      margin-left: -200px;
      opacity: 0;
    }
    100% {
      margin-left: 0;
      opacity: 1;
    }
  }
  @keyframes slideFromRight {
    0% {
      margin-right: -200px;
      opacity: 0;
    }
    100% {
      margin-right: 0;
      opacity: 1;
    }
  }
  @-webkit-keyframes slideFromRight {
    0% {
      margin-right: -200px;
      opacity: 0;
    }
    100% {
      margin-right: 0;
      opacity: 1;
    }
  }
  .all-chats, .all-scheduled-messages {
    .message-actions {
      position: absolute;
      top: 5px;
      right: 5px;
    }
    .message-actions .dropdown{
      visibility: hidden;
    }

    .chat-msg-e:hover .message-actions .dropdown, .chat-msg-e .message-actions .dropdown.open {
        visibility: visible !important;
    }

    .chat-msg-e:hover .bubble.me .message-actions .dropdown {
      background: transparent;
    }

    .chat-msg-e:hover .bubble.you .message-actions .dropdown {
      background: transparent;
    }

    .chat-msg-e:hover .action-list-options {
      z-index: 1 !important;
    }


    .bubble.me .message-actions > li > a:before {
      background: transparent;
      opacity: 0;
    }

    .bubble.you .message-actions > li > a:before {
      background: transparent;
      opacity: 0;
    }

    .chat-msg-e {
        .edited-true::after {
            font-family: 'Material-Design-Iconic-Font';
            content: '\f158';
            font-size: 16px;
            color: #D2D2D2;
        }
    }

    .message-actions > li > a > i {
      font-size: 25px;
      margin-right: 5px;
      position: absolute;
      right: 0px;
      top: -5px;
      color: #939393;
    }


    .action-list-options > li > a {
      width: 25px;
      height: 15px;
      line-height: 30px;
      display: inline-block;
      text-align: center;
      position: relative;

      &:hover {
          & > i {
              color: $m-black;
          }
      }
    }

    .image-msg {
      .dropdown {
        background: #E3F2FE !important;
        border-bottom-left-radius: 27px;
      }
    }
  }
  .chat-notify {
    text-align: center;
    .notify-text {
      border-radius: 10px;
      padding: 5px;
      color: $m-white;
      position: absolute;
      z-index: 10;
    }
    .info {
      background-color: $brand-primary;
    }
    .success {
      background-color: $m-green;
    }
  }
}

#eva-global-unread-count {
  position: absolute;
  color: #FFFFFF;
  font-style: normal;
  background: #1C5BFF;
  padding: 1px 5px;
  border-radius: 5px;
  right: 7px;
  top: 66px;
  font-size: 11px;
  line-height: 15px;
  font-weight: 600;
  -webkit-animation-name: wobble;
  animation-name: wobble;
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  letter-spacing: 0.5px;
}

.new-msg{
  top: 14px !important
}

.count-icon-position{
  right: 7px !important;
  top: 14px !important;
}

  #chat_bubble {
    border-radius: 50% !important;
    bottom: 20px !important;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.06), 0 2px 32px rgba(0, 0, 0, 0.16) !important;
    height: 60px !important;
    position: fixed !important;
    right: 20px !important;
    transition: box-shadow 80ms ease-in-out 0s !important;
    width: 60px !important;
    z-index: 2147483000 !important;

    .chat-launcher {
      backface-visibility: hidden;
      background: #0071b2 none repeat scroll 0 0;
      border-radius: 50%;
      cursor: pointer;
      font-family: intercom-font, Helvetica Neue, Helvetica, Arial, sans-serif;
      height: 60px;
      left: 0;
      overflow: hidden;
      position: absolute;
      top: 0;
      transform-origin: center center 0;
      width: 60px;

      .icons {
        bottom: 0;
        position: absolute;
        top: 0;
        transition: transform 0.16s linear 0s, opacity 0.08s linear 0s, -webkit-transform 0.16s linear 0s;
        width: 100%;
      }

      .open-chat-icon {
        // background-image: asset_url('chat/logo.png');
        background-position: center 0px;
        background-repeat: no-repeat;
        background-size: 60px 60px;
      }

      .close-chat-icon {
        // background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOBAMAAADtZjDiAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAG1BMVEUAAAD///////////////////////////8AAADr8xjQAAAAB3RSTlMAM7cPx7jIAE21/gAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxIAAAsSAdLdfvwAAABESURBVAjXYxAyYGBgYFZkUHcG0ialDCYlBgzM7slA7MxgUgaUNCkzdgfJMbunlIDUMpiUg7hwGiYOVQfTBzMHZi7UHgCB3RAZ7HszogAAAABJRU5ErkJggg==");
        background-position: center 23px;
        background-repeat: no-repeat;
        background-size: 14px 14px;
      }

      .unread-msg-count {
        color: rgb(255, 255, 255);
        padding: 5px;
        background-color: #f44336;
        position: fixed;
        z-index: 100;
        border-radius: 50%;
      }
    }
  }

#close-eva-chat {
  background: #fff none repeat scroll 0 0;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  position: absolute;
  right: 0;
  margin: -16px -10px 0px 0px;
  z-index: 1081;
  float: right;
}

.chat-notification {
  &.animated {
    &.alert-info {
      background-color: #2f96b4;
      padding: 10px 15px;

      .msg-details {
        width: 85%;

        .title, .message {
          max-height: 220px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 95%;

        }
      }
    }
  }
}

.unstyled-list-view {
    list-style-type: none;
}

.group-detail-view {
    list-style-type: none;
}

.description-text {
  word-break: break-word;
}

.chat-info-details, .group-chat-msg-info, .broadcast-msg-preview, .scheduled-msg-list, .broadcast-msg-replies {
  height: calc(100% - 74px);
  overflow-x: hidden;
  overflow-y: auto;

  .scheduled-msgs-area {
    height: calc(100% - 74px);
    overflow-x: hidden;
    overflow-y: auto;

    .edit-schedule, .cancel-schedule {
      cursor: hand;
    }
  }

  .msg-replies-area {
    margin-top: -15px;
    padding-top: 0px !important;
  }

  .replies-view-label {
    padding: 5px;
    font-weight: bold;

    .replies-note {
        font-size: 9px;
    }
    font-size: 14px;
  }

  .msg-preview-area {
    background-color: #f8f8f8;
    word-break: break-word;
  }

  .header-container {
      background-color: $m-cyan;
      color: $m-white;
      font-size: 17px;

      .zmdi {
          color: $m-white;
      }
  }

    .participant-container{
        .participant-list{
            width: 40%;
        }
    }

  .body-container {
      .right-container {
        .prop-name {
          float: left;
          width: 40%;
          word-wrap: break-word;
          color: #999;
        }
        .prop-value {
          float: right;
          width: 60%;
          word-wrap: break-word;
        }
      }

      .person {
          width: 100%;
          padding: 2px 2% 2px;
          background-color: #f8f8f8;
          border-bottom: 1px solid #eee;

          img {
              width: 40px;
              height: 40px;
              margin-right: 12px;
              border-radius: 50%;
              border: 2px solid #fff;
          }

          .details {
              width: 50%;
              white-space: nowrap;
              text-overflow: ellipsis;

              .name {
                  color: #1a1a1a;
                  font-size: 14px;
                  line-height: 22px;
                  font-family: 'Source Sans Pro', sans-serif;
                  font-weight: 500;
                  width: 80%;
                  overflow: hidden !important;
                  text-overflow: ellipsis;
              }
              .preview {
                  font-size: 13px;
                  color: #999;
                  overflow: hidden !important;
                  text-overflow: ellipsis;
              }
          }

          //&.active, &:hover {
          //    background-color: #1e88e5;
          //
          //    .details {
          //        .name, .preview {
          //            color: #fff;
          //        }
          //    }
          //}
      }

    .epc-item {
      margin-bottom: 10px;
      padding: 30px 20px;
      position: relative;
      text-align: center;
    }

    .easy-pie {
      display: inline-block;
      padding: 0 5px 10px;
      position: relative;
    }

    .easy-pie .headline {
      font-weight: 300;
      left: 0;
      line-height: 100%;
      position: absolute;
      width: 100%;
    }
    .easy-pie .percent::after {
      content: "%";
    }
    .easy-pie.cpu-pie .headline {
      font-size: 45px;
      margin-top: 35px;
      text-align: center;
    }
    .easy-pie.cpu-pie .headline::after {
      font-size: 30px;
    }
    .easy-pie:not(.cpu-pie) .headline {
      font-size: 26px;
      margin-top: 30px;
    }
    .easy-pie:not(.cpu-pie) .headline::after {
      font-size: 20px;
    }
    .easy-pie .pie-title {
      bottom: -15px;
      left: 0;
      position: absolute;
      text-align: center;
      width: 100%;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
}

.attachment-icon {
  font-size: 20px;
  position: relative;
  top: 5%;
}

#schedule_form {
  height: calc(69% - 50px);
  overflow-x: hidden;
  overflow-y: auto;

  .panel-heading {
    .rotate {
      -webkit-transform: rotate(-180deg); /* Chrome, Safari, Opera */
      -moz-transform: rotate(-180deg); /* Firefox */
      -ms-transform: rotate(-180deg); /* IE 9 */
      transform: rotate(-180deg); /* Standard syntax */
    }
  }

  .scm-frequency {
    width: 45%;
    position: absolute;
    right: 10px;
    bottom: 15px;
  }


  .bootstrap-datetimepicker-widget table td {
    height: 20px;
    line-height: 20px;
    width: 0px;
  }

  .bootstrap-datetimepicker-widget table td span {
    width: 45px;
    height: 40px;
    line-height: 45px;
  }
}

.scheduled-msg-list {
  .empty {
    min-height: 300px !important;
  }
  .empty-content {
    position: absolute;
    top: 50%;
    width: 100% !important;

    .new-schedule-message-btn {
      margin-top: 30px;
    }
  }
}

#all_chats, #all_scheduled_messages {
  height: calc(100% - 136px);
  overflow-x: hidden;
  overflow-y: auto;

  .more-messages-arrow {
    // @include z-depth-bottom(1);
    position: absolute;
    bottom: 85px;
    right: 20px;
    font-size: 40px;
    cursor: pointer;
    z-index: 1080;

    align-items: center;
    background-color: $m-cyan;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    width: 42px;
    height: 42px;

    a {
      color: #fff;

      .zmdi {
          font-size: 35px;
      }
    }

    .new-msg-count {
        background: $m-red;
        border-radius: 12px;
        color: $m-white;
        display: inline-flex;
        font-size: 14px;
        height: 24px;
        min-width: 24px;
        justify-content: center;
        left: -10px;
        padding: 2px 4px;
        position: absolute;
        top: -10px;
    }
  }
  .system-message {
    color: #fff;
    text-align: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;

    .msg {
      padding: 10px;
      background: $m-cyan;
      border-radius: 2px;

      &.msg-alert {
        background: $m-amber !important;
      }

      &.msg-warn {
        background: $m-red !important;
      }

      &.msg-lightgreen {
        background: $m-lightgreen !important;
      }

      &.msg-bluegray {
        background: $m-bluegray !important;
      }
    }

    .time {
      color: #b3b3b3;
      margin-top: 7px;
      text-align: left;
    }
  }
}

img.emoji {
  height: 20px;
  margin-left: 5px;
}

/* request access form specific */
.request-access {
  .free-beta {
    width: 91px;
    height: 30px;
    border-radius: 4px;
    border: solid 1px #f91a28;
    background-color: transparent;
    color: #f91a28;
    font-size: 12px;
    top: 0;
    position: absolute;
    margin-left: 10px;
    font-stretch: normal;
    font-family: Roboto;
    letter-spacing: normal;
    font-style: normal;
    line-height: 2;
    color: #f91927;
    font-size: 12px;
    font-weight: 500;
  }
  h4{
  font-size: 14px;
  }
  .eva-chat-icon{
    // background-image: url("chat/logo.png");
    padding: 30px;
    background-repeat: no-repeat;
    background-size: contain;
    box-shadow: none;
    margin-top: -5px;
  }
  .simple-desc {
    font-stretch: normal;
    font-family: Roboto;
    letter-spacing: normal;
    font-style: normal;
    line-height: 1.36;
    color: #757575;
    font-size: 14px;
    font-weight: normal;
  }
  .icon-realtime {
    // @include z-depth-bottom(1);
    // background-image: url("chat/ic-timmer-new.png");
    padding: 16px;
    background-repeat: no-repeat;
    background-size: contain;
    box-shadow: none;
  }
  .icon-enterprise {
    // @include z-depth-bottom(1);
    // background-image: url("chat/ic-phone-new.png");
    padding: 16px;
    background-repeat: no-repeat;
    background-size: contain;
    box-shadow: none;
  }
  .icon-lightning {
    // @include z-depth-bottom(1);
    // background-image: url("chat/ic-lightning.png");
    padding: 16px;
    background-repeat: no-repeat;
    background-size: contain;
    box-shadow: none;
  }
  .icon-deviceplaystore {
    // @include z-depth-bottom(1);
    // background-image: url("chat/ic-exclusive-new.png");
    padding: 16px;
    background-repeat: no-repeat;
    background-size: contain;
    box-shadow:none;
  }
  .c-darkblue {
    color: #3F51B5;
  }
  .f-100 {
    font-weight: 100px;
  }
  .f-11 {
    font-size: 11px;
  }
  .f-37 {
    font-size: 37px;
  }
  .right-line {
    border-right-style: solid;
    border-right-width: 1px;
    border-right-color: #eeeeee;
    height: 90%;
    position: absolute;
    right: 20px;
    top: 15px;
  }

  .f-w-100 {
    font-weight: 100;
  }
  .p-t-40 {
    padding-top: 40px;
  }
  .d-flex {
    display: flex;
  }
  .d-in {
    display: inline-block;;
  }
  .supercharge-text {
    padding-bottom: 5px;
    font-stretch: normal;
    font-family: Roboto;
    letter-spacing: normal;
    font-style: normal;
    line-height: normal;
    color: #333333;
    font-size: 14px;
    font-weight: normal;
  }
  .yearly-text {
    width: 283px;
    height: 24px;
    font-stretch: normal;
    font-family: Roboto;
    letter-spacing: normal;
    font-style: normal;
    line-height: 1.85;
    color: #626262;
    font-size: 13px;
    font-weight: normal;
  }
  @media screen and (min-width: 768px) and (max-width: 1209px) {
    .free-beta {
      position: relative !important;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 990px) {
    .right-line {
      display: none;
    }
  }
}

.max_call_container {
  position: fixed !important;
  right: 0px !important;
  top: 0px !important;
  left: 0px !important;
  bottom: 0px !important;
  width: 100% !important;
  height: 100% !important;
  opacity: 1 !important;
  border-radius: 0px !important;

  .nuovoteam-logo {
    display: block !important;
    position: absolute;
    bottom: 2%;
    left: 2%;
  }

  .micro-phone-mute {
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    .state{
      i {
        font-size: 15px;
        margin-right: 5px;
      }
      text-align: center;
      padding: 10px;
      font-size: 13px;
      position: absolute;
      height: 40px;
      width: 200px;
      top: 15px;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), rgba(0, 0, 0, 0.5);
      border: 0.5px solid rgba(20, 20, 20, 0.105639);
      border-radius: 18px;
      z-index: 11;
    }
  }

  .call-actions {
    .col-md-12 {
      position: absolute;
      bottom: 10%;
    }
  }

  .call-user-name {
    position: absolute;
    top: 3%;
    left: 2%;
  }

  #call-status {
    position: absolute;
    top: 6%;
    left: 2%;
    padding-top: 5px !important;
    padding-left: 0px !important;
  }

  #maximize-video-btn {
    top: 3% !important;
    right: 2% !important;
  }

  .call-user-avatar {
    position: absolute;
    top: 30%;
    text-align: center;

    img {
      display: inline;
      width: 15% !important;
    }
  }

  #localVideo {
    position: absolute;
    display: block;
    bottom: 0;
    right: 2%;
    width: 200px;
    border-radius: 8px;
  }
}

#call_container {
  z-index: 1200;
  overflow: hidden;
  position: fixed;
  right: 30px;
  top: 75px;
  width: 180px;
  height: 155px;
  background-color: #444;
  margin: 0;
  background-color: #000000;
  box-shadow: 4px 4px 22px 0 rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  opacity: 1;
  letter-spacing: 0.6px;
  a {
    text-decoration: none;
  }

  .micro-phone-mute {
    display: none;
  }

  .nuovoteam-logo {
    display: none;
  }
  .caller-name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 20px;
    .call-user-name {
      width: 90px;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;
      white-space: nowrap;
    }
  }

  #remoteVideo {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
  }

  #localVideo {
    display: none;
  }

  .popup {
    overflow: hidden;
    cursor: all-scroll;
    font-size: 12px;
    color: #fff;
  }
  .call-user-avatar {
    width: 100%;
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 25%;
      border-radius: 50%;
      border: 2px solid #fff;
    }
  }

  #maximize-video-btn {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 25px;
    cursor: pointer;
    color: #fff;
    z-index: 999;
  }

  .call-actions {
    font-size: 25px;
    a {
      cursor: pointer;
    }
    .zmdi-phone-end {
      color: #FF0019;
    }
    .zmdi-phone {
      color: #00BD53;
    }
    .zmdi-mic, .zmdi-mic-off, .zmdi-videocam, .zmdi-videocam-off {
      color: #fff;
    }
    #mute-help {
      font-size: 12px;
    }
  }
  .call-feedback-title {
    font-size: 12px;
  }
  .feedback-star {
    cursor: pointer;
    .zmdi-star {
      font-size: 20px;
      color: #5E6162;
      padding-right: 2px;
    }
  }
  .feedback-actions {
    #later-feedback-btn {
      border-right: solid 1px #4a4a4a;
    }
    a {
      color: #fff;
      cursor: pointer;
    }
  }
  span#wave {
    width:30px;
    height:10px;
    .dot {
      display:inline-block;
      width:2px;
      height:2px;
      border-radius:50%;
      margin-right:3px;
      background:#fff;
      animation: wave 1.3s linear infinite;

      &:nth-child(2) {
        animation-delay: -1.1s;
      }

      &:nth-child(3) {
        animation-delay: -0.9s;
      }
    }
  }

  @keyframes wave {
    0%, 60%, 100% {
      transform: initial;
    }

    30% {
      transform: translateY(-5px);
    }
  }
}

#ptt_container {
  z-index: 1200;
  overflow: hidden;
  position: fixed;
  right: 30px;
  top: 75px;
  width: 180px;
  height: 120px;
  margin: 0;
  background-color: #0d0f4e;
  box-shadow: 4px 4px 22px 0 rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  opacity: 1;
  letter-spacing: 0.6px;

  .caller-name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 20px;
    .call-user-name {
      width: 90px;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;
      white-space: nowrap;
    }
  }

  .popup {
    overflow: hidden;
    cursor: all-scroll;
    font-size: 12px;
    color: #fff;
  }
  .call-user-avatar {
    width: 100%;
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 25%;
      border-radius: 50%;
      border: 2px solid #fff;
    }
  }
}

.eva-audio-player:not(.playing) {
  .audioRange {
    pointer-events: none;
  }
  progress {
    cursor: not-allowed;
  }
}

.ql-editor {
  strong {
    font-weight: bold;
  }
  line-height: 1 !important;
  min-height: 50px !important;
  max-height: 158px !important;
  padding: 5px 15px !important;
  ::before {
    content: attr(data-placeholder);
  }

}

.ql-container {
  height: 70% !important;
  max-height: 70% !important;
}


.recording-actions {
  width: 90%;
  display: inline-block;
  background: #fff;
  border: 1px solid #e6e6e6;
  .recording-info {
    margin: 5px 5px;
  }
  .recording-time {
    position: relative;
    bottom: 3px;
  }

  .cancel-recording {
    font-size: 20px;
    padding-left: 5px;
  }

  .rec {
    width: 10px;
    height: 10px;
    background-color: $m-red;
    border: 0;
    border-radius: 35px;
    outline: none;
    display: inline-block;
    position: relative;
    bottom: 3px;
  }
  .rec.pulse{
    animation-name: pulse;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }


  @keyframes pulse{
    0%{
      box-shadow: 0px 0px 5px 0px rgba(173,0,0,.3);
    }
    65%{
      box-shadow: 0px 0px 5px 5px rgba(173,0,0,.3);
    }
    90%{
      box-shadow: 0px 0px 5px 5px rgba(173,0,0,0);
    }
  }
}

.eva-audio-player {
  width: 20vw;
  .player-controls {
    padding-top: 4px;
      .slidecontainer {
        width: 100%;
        padding-top: 8px;
      }
      .seekObjContainer {
        input {
            position: absolute;
            @-moz-document url-prefix() {
              top: 16px;
            }
            bottom: 27px;
            width: 70%;
        }

        progress {
          width: 154px;
        }

        progress[value] {
            -webkit-appearance: none;
               -moz-appearance: none;
                    appearance: none;
            border: none;
            height: 2px;
        }

        progress[value]::-webkit-progress-value {
            background-color: #419BF9;
        }

        progress[value]::-moz-progress-bar {
            background-color: #419BF9;
        }

        input[type="range"] {
            -webkit-appearance: none;
            -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
            -moz-apperance: none !important;
            background: none;
            outline: none !important;
            border: none;
            margin: 0;
            padding: 0;
        }

        input[type="range"]:focus {
            outline: none;
            border: none;
        }

        input[type="range"]::-moz-range-track {
            border: none;
            background: transparent;
            outline: none;
        }

        input[type="range"]::-ms-track {
            border: inherit;
            color: transparent; /* don't drawn vertical reference line */
            background: transparent;
        }

        input[type="range"]::-webkit-slider-thumb {
            -webkit-appearance: none;
            background-color: #A8A8A8;
            height: 10px;
            width: 10px;
            border-radius: 50%;
        }

        input[type="range"]::-moz-range-thumb {
            -moz-appearance: none;
            background: #A8A8A8;
            height: 10px;
            width: 10px;
            border-radius: 50%;
            border: none;
        }

        input[type="range"]::-ms-thumb {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            border: 0;
            background: #A8A8A8;
        }

        input[type="range"]::-ms-fill-lower,
        input[type="range"]::-ms-fill-upper {
          background: transparent;
        }

        input[type="range"]::-ms-tooltip {
          display: none;
        }
      }

      .seekObjContainer.true {
        input {
         top: 37px;
        }
      }


  }

}
.total-length {
  font-size: 11px;
}

#eva-image-viewer {
  .modal {
    display: none;
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: black;
  }

  /* Modal Content */
  .modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    width: 90%;
    max-width: 1200px;
  }

  .close {
    color: white;
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 35px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: #999;
    text-decoration: none;
    cursor: pointer;
  }
}

.preview-container {
  position: absolute;
  z-index: 1080;
  background: #111;
  top: 0;
  width: 100%;
  animation: ease 0.5s forwards;
  #close-eva-preview {
    position: absolute;
    left: 20px;
    top: 12px;
    color: #fff;
    font-size: 24px;
  }
  .image-preview {
    height: 550px;
    display: flex;
    justify-content: center;
    img {
      max-height: 550px;
      max-width: 80%;
    }
    background: #111;
    width: 100%;
    .image-container {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: .5s ease;
      #caption-container, #caption_msg {
          display: block;
          position: absolute;
          bottom: 10px;
          color: white;
          padding: 10px;
          background: rgba(0,0,0,0.5);
          text-align: center;
          width: -webkit-fill-available;
          word-break: break-all;
      }
    }
  }
  .edit-container {
    display: block;
    position: absolute;
    bottom: 30px;
    width: -webkit-fill-available;
    .caption_msg {
      border: none;
      border-bottom: 2px solid #fff;
      bottom: 0;
      color: #fff;
      font-family: "intercom-font","Helvetica Neue",Helvetica,Arial,sans-serif;
      font-size: 15px;
      font-weight: 400;
      overflow-wrap: break-word;
      padding: 8px 10px;
      white-space: pre-wrap;
      width: 65%;
      margin-left: 15%;
      background: transparent;
      cursor: text;
      ::placeholder {
        color: rgba(225,225,225,0.5);
      }

    }
    .caption-btn {
      padding: 8px 10px;
    }


  }

}

.message-action-btn.download {
  &:before {
    font-family: 'Material-Design-Iconic-Font';
    content: '\f220';
    padding-right: 5px;
    font-size: 25px;
    color: #939393;
    cursor: pointer;
  }
}

.message-action-btn.download-file {
  &:before {
    font-family: 'Material-Design-Iconic-Font';
    content: '\f220';
    margin-right: 5px;
    font-size: 25px;
    color: #939393;
    cursor: pointer;
  }
}

.message-action-btn.play {
  &:before {
    font-family: 'Material-Design-Iconic-Font';
    content: '\f3aa';
    padding-right: 5px;
    font-size: 25px;
    color: #939393;
    cursor: pointer;
  }
}

.message-action-btn.retry {
  &:before {
    font-family: 'Material-Design-Iconic-Font';
    content: '\f22a';
    padding-right: 5px;
    font-size: 25px;
    color: #939393;
    cursor: pointer;
  }
}

.message-action-btn.pause {
  &:before {
    font-family: 'Material-Design-Iconic-Font';
    content: '\f3a7';
    padding-right: 5px;
    font-size: 25px;
    color: #939393;
    cursor: pointer;
  }
}


.message-action-btn.sending {

  // &:before {
  //   font-family: 'Material-Design-Iconic-Font';
  //   content: '\f3ec';
  //   margin-right: 5px;
  //   font-size: 25px;
  //   color: #939393;
  //   cursor: pointer;
  //   -webkit-animation: zmdi-spin 1.5s infinite linear;
  //   animation: zmdi-spin 1.5s infinite linear;
  // }

  $blue: #0057e7;

  .loader {
    display: block !important;
    position: relative;
    margin: 5px auto;
    width: 30px;
    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }
  }

  .circular {
    animation: rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }

  @keyframes color {
    100%,
    0% {
      stroke: $blue;
    }

  }
}
.top {
  .start-call-btn, .start-vid-call-btn, .new-schedule-message-btn, .action-list-options > li > a {
    display: inline-block;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    color: $brand-primary;
    font-size: 20px;
    @include hover-pop(rgba(0, 0, 0, 0.1), 50%, 250ms, 0);
    color: #adadad;

  }
  .start-call-btn, .start-vid-call-btn {
    color: #3F51B5;
  }
}

.text-webkit-ceter {
  text-align: -webkit-center;
}


.max-session-used {
  p {
    margin-bottom: 0px;
  }
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  border-radius: 10px;
}

.attach-bubble {
  padding: 5px !important;
  .file-icon {
    background: url('/assets/icn_unknown.png') no-repeat;
    width: 25px;
    height: 24px;
    margin-right: 5px;
    vertical-align: middle;
    display: inline-block;
  }

  .card {
    cursor: pointer;
  }
  .card.selected {
    background: $m-blue;
    color: $m-white;
  }
  /* ------------------- CSS for List View End ------------------- */

  /* ------------------- CSS for Grid View Start ------------------- */
  .file-icon-2x {
    background-size: contain;
    background: url('/assets/icn_unknown@2x.png') no-repeat center center;
    height: 75px;
    vertical-align: middle;
  }

  /* ------------------- Documents file Icons ------------------- */
  .file-icon-2x.file-txt {
    background: url('/assets/icn_txt@2x.png') no-repeat center center;
  }
  .file-icon-2x.file-docx, .file-icon-2x.file-doc {
    background: url('/assets/icn_docs@2x.png') no-repeat center center;
  }
  .file-icon-2x.file-xlsx, .file-icon-2x.file-xls {
    background: url('/assets/icn_xls@2x.png') no-repeat center center;
  }
  .file-icon-2x.file-pdf {
    background: url('/assets/icn_pdf@2x.png') no-repeat center center;
  }
  .file-icon-2x.file-pptx, .file-icon-2x.file-ppt {
    background: url('/assets/ic_powerpoint@2x.png') no-repeat center center;
  }


  .file-icon.file-txt {
    background-size: auto 100%;
    background: url('/assets/icn_txt.png');
  }
  .file-icon.file-docx, .file-icon.file-doc {
    background-size: auto 100%;
    background-image: url('/assets/icn_docs.png');
  }
  .file-icon.file-xls, .file-icon.file-xlsx {
    background-size: auto 100%;
    background-image: url('/assets/icn_xls.png');
  }
  .file-icon.file-pdf {
    background-size: auto 100%;
    background-image: url('/assets/icn_pdf.png');
  }
  .file-icon.file-pptx, .file-icon.file-ppt {
    background-size: auto 100%;
    background-image: url('/assets/ic_powerpoint.png');
  }

  /* ------------------- Video file Icons ------------------- */

  .file-icon-2x.file-mp4, .file-icon-2x.file-avi, .file-icon-2x.file-mkv,
  .file-icon-2x.file-3ga, .file-icon-2x.file-3gp, .file-icon-2x.file-webm  {
    background: url('/assets/ic_video@2x.png') no-repeat center center;
  }

  .file-icon.file-avi, .file-icon.file-mp4, .file-icon.file-mkv,
  .file-icon.file-3ga, .file-icon.file-3gp, .file-icon.file-webm {
    background-size: auto 100%;
    background-image: url('/assets/ic_video.png');
  }

  /* ------------------- Audio file Icons ------------------- */
  .file-icon-2x.file-m4a, .file-icon-2x.file-mp3, .file-icon-2x.file-wav,
  .file-icon-2x.file-ogg, .file-icon-2x.file-amr, .file-icon-2x.file-aac,
  .file-icon-2x.file-opus {
    background: url('/assets/ic_audio@2x.png') no-repeat center center;
  }

  .file-icon.file-m4a, .file-icon.file-mp3, .file-icon.file-ogg,
  .file-icon.file-wav, .file-icon.file-aac, .file-icon.file-amr,
  .file-icon.file-opus {
    background-size: auto 100%;
    background-image: url('/assets/ic_audio.png');
  }

  /* ------------------- Images Icons ------------------- */
  .file-icon-2x.file-gif {
    background: url('/assets/ic_picture@2x.png') no-repeat center center;
  }

  .file-icon.file-gif {
    background-size: auto 100%;
    background-image: url('/assets/ic_picture.png');
  }

  .file-icon-2x.file-jpg {
    background: url('/assets/ic_picture@2x.png') no-repeat center center;
  }

  .file-icon.file-jpg {
    background-size: auto 100%;
    background-image: url('/assets/ic_picture.png');
  }

  .file-icon-2x.file-png {
    background: url('/assets/ic_picture@2x.png') no-repeat center center;
  }

  .file-icon.file-png {
    background-size: auto 100%;
    background-image: url('/assets/ic_picture.png');
  }

  .file-icon-2x.file-jpeg {
    background: url('/assets/ic_picture@2x.png') no-repeat center center;
  }

  .file-icon.file-jpeg {
    background-size: auto 100%;
    background-image: url('/assets/ic_picture.png');
  }

  .grid-view .box {
    cursor: pointer;
  }
  .grid-view .box.selected {
    @include z-depth(1);
    background: $m-white;
  }
  .grid-view .overflow-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0 auto;
  }
  /* ------------------- CSS for Grid View End ------------------- */

  #info-footer {
    background-color: $m-white;
    table td {
      padding-left: 20px;
    }
    .file-shortcut {
      display: inline;
    }
    .mcm-options {
      a {
        text-align: left;
      }
    }
    .p-r-50 {
      padding-right: 50px;
    }
    .icon-resize {
      font-size: 12px;
      vertical-align: top;
    }
  }
}

@import './variables.scss';
@import './mixins.scss';

.tab-nav {
    list-style: none;
    padding: 0;
    margin: 0;
    
    & > li {
        & > a {
            display: block;
            color: #7a7a7a;
            text-transform: uppercase;
            position: relative;
            font-weight: 500;
            text-decoration: none;
            
            @media (min-width: $screen-sm-min) {
                padding: 15px;
            }

            @media (max-width: $screen-sm-min) {
                padding: 15px 8px;
            }
        }
    }
    
    &:not(.tn-vertical) {
        white-space: nowrap; 
        overflow: auto;
        box-shadow: inset 0 -2px 0 0 #eee;

        & > li {
            display: inline-block;
            vertical-align: top;

            & > a {
                @include transition(all);
                @include transition-duration(250ms);
                    
                &:after { 
                    content: "";
                    height: 4px;
                    position: absolute;
                    width: 100%; 
                    left: 0;
                    bottom: 0;
                    @include transition(all);
                    @include transition-duration(250ms);
                    @include scale(0);
                }
            }

            &.active {
                & > a { 
                    color: #000;

                    &:after {
                        @include scale(1);
                    }
                } 
            }
        }

        &.tab-nav-right {
            text-align: right;
        }

        &.tn-justified {
            & > li {
                display: table-cell;
                width: 1%;
                text-align: center;
            }
        }

        &.tn-icon {
            & > li {
                .zmdi {
                    font-size: 22px;
                    line-height: 100%;
                    min-height: 25px;   
                }
            }
        }

        &:not([data-tab-color]) {
            & > li > a:after {
                background: $m-blue;
            }
        }

        &[data-tab-color="green"] {
            & > li > a:after {
                background: $m-green;
            }
        } 

        &[data-tab-color="red"] {
            & > li > a:after {
                background: $m-red;
            }
        }

        &[data-tab-color="teal"] {
            & > li > a:after {
                background: $m-teal;
            }
        }

        &[data-tab-color="amber"] {
            & > li > a:after {
                background: $m-amber;
            }
        }

        &[data-tab-color="black"] {
            & > li > a:after {
                background: $m-black;
            }
        }

        &[data-tab-color="cyan"] {
            & > li > a:after {
                background: $m-cyan;
            }
        }

        &[data-tab-color="indigo"] {
            & > li > a:after {
                background: $m-indigo;
            }
        }
    }
}

.tn-vertical {
    float: left;
    
    & > li {  
        
        & > a > {
            border-right: 2px solid #eee;
        }
        
        &.active > a {
            border-top: 2px solid #eee;
            border-bottom: 2px solid #eee;
            border-left: 2px solid #eee;
            border-radius: 5px 0 0 5px;
            position: relative;
            border-right-color: #fff;
            
            &:after {
                content: "";
                height: 100%;
                width: 2px;
                right: 0;
                top: -2px;
                background: #fff;
                z-index: 1;
            }
        }
    }
    
    & + .tab-content {
        overflow: hidden;
        vertical-align: top;
        padding: 0 30px;
    }
}

.tab-content {
    padding: 20px 0;
}

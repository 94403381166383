@import "./bootstrap-sass.scss";

@mixin bg-option($repeat: no-repeat, $position: center) {
  background-repeat: $repeat;
  background-position: $position;
}

@mixin opacity($value) {
  $IEValue: $value * 100;
  opacity: $value;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" + $IEValue + ")";
  filter: alpha(opacity=$IEValue);
}

/*
 * CSS Animations based on animate.css
 */

@mixin animated($name, $duration) {
  -webkit-animation-name: $name;
  animation-name: $name;
  -webkit-animation-duration: $duration;
  animation-duration: $duration;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

/*
 * CSS Transform - Scale and Rotate
 */
@mixin scale-rotate($scale, $rotate) {
  -webkit-transform: scale($scale) rotate($rotate);
  -ms-transform: scale($scale) rotate($rotate);
  -o-transform: scale($scale) rotate($rotate);
  transform: scale($scale) rotate($rotate);
}

/*
 * User Select
 */
@mixin user-select($val) {
  -webkit-touch-callout: $val;
  -webkit-user-select: $val;
  -khtml-user-select: $val;
  -moz-user-select: $val;
  -ms-user-select: $val;
  user-select: $val;
}

/*
 * Background Image Cover
 */
@mixin bg-cover($image) {
  background-image: url($image);
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}

@mixin bg-cover-inline() {
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}

/*
 * Tab Focus
 */
@mixin tab-focus() {
  outline: none !important;
}

/*
 * Pop-in Hover effects
 */
@mixin hover-pop($background: unquote("rgba(0,0,0,0.5)"), $radius: 0, $duration: 250ms, $zindex: 0) {
  position: relative;

  &:before {
    left: 0;
    top: 0;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    @include scale3d(0, 0, 0);
    @include transition(all);
    @include transition-duration($duration);
    @include backface-visibility(hidden);
    background-color: $background;
    z-index: $zindex;
    border-radius: $radius;
    @include opacity(0);
  }

  &:hover {
    &:before {
      @include scale3d(1, 1, 1);
      @include opacity(1);
    }
  }
}

/*
 *  Override Bootstrap Button Mixin
 */
@mixin button-variant($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:focus,
  &.focus {
    color: $color;
    background-color: $background;
    border-color: transparent;
  }

  &:active,
  &.active,
  .open > .dropdown-toggle {
    background-image: none;
    background-color: darken($background, 15%);
  }

  &.disabled,
  &[disabled],
  fieldset[disabled],
  &:hover,
  &:focus,
  &.focus,
  &:active {
    background-color: $background;
    border-color: $border;
  }

  .badge {
    color: $background;
    background-color: $color;
  }
}

/*
 * Scale 3d
 */
@mixin scale3d($options...) {
  //$process: unquote(`(function(e){return e=e||"1, 1, 1"})((function(){var e="${arguments}";return e=e.replace(/^\[|\]$/g,"")})())`);
  -webkit-transform: scale3d($options);
  -moz-transform: scale3d($options);
  -ms-transform: scale3d($options);
  -o-transform: scale3d($options);
  transform: scale3d($options);
}

// Retina Height/width/padding etc
//
// Short retina mixin for setting height. Note that the
// spelling of `min--moz-device-pixel-ratio` is intentional.
@mixin retina-dimen($prop, $height-1x, $height-2x) {
  #{$prop}: $height-1x;

  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    #{$prop}: $height-2x;
  }
}

/**
 * Grayscale mixin, allows an element to be grayscaled
 */
@mixin grayscale() {
  /* Firefox 10-34 */
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='saturate' values='0'/></filter></svg>#grayscale");

  /*
    Chrome 19+,
    Safari 6+,
    Safari 6+ iOS,
    Opera 15+
  */
  -webkit-filter: grayscale(1);

  /* Firefox 35+ */
  filter: grayscale(1);

  /* IE 6-9 */
  filter: gray;
}

/**
 * Mixin to remove Grayscale filter
 */
@mixin grayscale-off() {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

/**
 * Text Blend Mode
 * @see https://css-tricks.com/almanac/properties/m/mix-blend-mode/
 */
@mixin blend($value: exclusion) {
  mix-blend-mode: $value;
}

/**
 * Simple transform
 */
@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

/**
 * Font smoothing styles
 */
@mixin font-smoothing($value: on) {
  @if $value == on {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

/**
 * All four positions are set as zero
 */
@mixin position-zero($position: absolute) {
  position: $position;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

/**
 * Mixin to customize scrollbars (Webkit browsers). Beware, this does not work in all browsers
 *
 * @param {Length} $size - Horizontal scrollbar's height and vertical scrollbar's width
 * @param {Color} $foreground-color - Scrollbar's color
 * @param {Color} $background-color [mix($foreground-color, white, 50%)] - Scrollbar's color
 * @example scss - Scrollbar styling
 *   @include scrollbars(.5em, slategray);
 */
@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, $m-white, 50%)) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  // body {
  //   scrollbar-face-color: $foreground-color;
  //   scrollbar-track-color: $background-color;
  // }
}

@mixin clearfix() {
  &:before,
  &:after {
    content: " ";
    display: table;
  }
}

//
// Name:           Material Shadows
// Description:    Paper shadows of material design.
// Version:        2.0.1
//
// Author:         Denis Malinochkin
// Git:            https://github.com/mrmlnc/material-shadows
//
// twitter:        @mrmlnc
//
// ------------------------------------

// Mixins
// ------------------------------------

// Generator for top shadow
@function z-depth-top($depth: 1) {
  $color:    .12, .19, .19, .21, .22;
  $offset-y: 2px, 6px, 17px, 25px, 40px;
  $blur:     10px, 20px, 50px, 55px, 77px;

  @return 0 nth($offset-y, $depth) nth($blur, $depth) rgba(0, 0, 0, nth($color, $depth));
}

@mixin z-depth-top($depth: 1) {
  box-shadow: z-depth-top($depth);
}

// Generator for bottom shadow
@function z-depth-bottom($depth: 1) {
  $color:    .16, .2, .24, .22, .2;
  $offset-y: 2px, 8px, 12px, 16px, 27px;
  $blur:     5px, 17px, 15px, 28px, 24px;

  @return 0 nth($offset-y, $depth) nth($blur, $depth) rgba(0, 0, 0, nth($color, $depth));
}

@mixin z-depth-bottom($depth: 1) {
  box-shadow: z-depth-bottom($depth);
}

// Generator for top and bottom shadow
@mixin z-depth($depth: 1) {
  box-shadow: z-depth-bottom($depth), z-depth-top($depth);
}

// Generator animation hover and focus effect
@mixin z-depth-animation($depth, $orientation: full) {
  &:hover,
  &:focus {
    @if $orientation == top {
      @include z-depth-top($depth);
    }
    @else if $orientation == bottom {
      @include z-depth-bottom($depth);
    }
    @else {
      @include z-depth($depth);
    }
  }
}

// Classes
// ------------------------------------

@mixin z-depth-class($animation: true, $time: .28s, $function: cubic-bezier(.4, 0, .2, 1)) {
  @for $i from 1 to 6 {
    &-#{$i} {
      @include z-depth($i);

      &-top {
        @include z-depth-top($i);
      }

      &-bottom {
        @include z-depth-bottom($i);
      }
    }


    // z-depth-animation
    &-animation {
      &:hover {
        .z-depth-1,
        .z-depth-2,
        .z-depth-3,
        .z-depth-4,
        .z-depth-5 {
          transition: box-shadow $time $function;
        }
      }
    }
  }
}

@import './variables.scss';

.alert {
    padding-left: 30px;
    font-size: 13px;

    span {
        cursor: pointer;
    }

    // &:not(.alert-dismissible) {
    //     padding-right: 30px;
    // }

    &.alert-dismissable {
        padding-right: 44px;
    }
}

.alert-inverse {
    background: rgba(#333, 0.9);
    box-shadow: 0 0 5px rgba(#333, 0.5);
    color: #fff;

    a {
        color: #fff;
    }
}

.animated {
    border: 0;
    color: #fff;

    &.alert-info {
        background: rgba($m-blue, 0.8);
        box-shadow: 0 0 5px rgba($m-blue, 0.5);
    }

    &.alert-success {
        background: rgba($m-green, 0.8);
        box-shadow: 0 0 5px rgba($m-green, 0.5);
    }

    &.alert-warning {
        background: rgba($m-orange, 0.8);
        box-shadow: 0 0 5px rgba($m-orange, 0.5);
    }

    &.alert-danger {
        background: rgba($m-red, 0.8);
        box-shadow: 0 0 5px rgba($m-red, 0.5);
    }
}
